.VideoPlayer .react-player {
    /*position: relative;
    width: 100%!important;
    height: 0!important;
    padding-bottom: 56.25%;*/
    margin: 0;
    width: 100%!important;
    height: 100%!important;
}

.VideoPlayer .react-player iframe {
    /*position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;*/
    display: block;
    height: 70vh;        /* Viewport-relative units */
    width: 80vw;
    max-width: 100%;
    max-height: 70%!important;
    overflow: hidden;
    overflow-y: hidden;
    overflow-x: hidden;
    margin: 0 auto;
}

.VideoPlayer button {
    font-size: 24px;
    margin-top: 40px;
    padding: 7px 22px;
    opacity: 0.8;
}

.VideoPlayer button:hover {
    opacity: 1;
    cursor: pointer;
}

@media only screen 
and (max-width : 800px) {
    .VideoPlayer .react-player iframe {
        height: 60vh;
        width: 100vw;
    }
}
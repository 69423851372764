html, body {
  background-color: #000;
  margin: 0;
}

body {
  background-image: url("./assets/repeating_background.png");
  background-repeat: repeat;
  background-attachment: fixed;
}

h1 {
  color: #fff;
  font-family: "Livvic", Arial, Helvetica, sans-serif;
  padding: 20px 0 18px 0;
  margin: 0;
  font-size: 48px;
}

h2 {
  color: #52ffcf;
  font-size: 14px;
  font-weight: normal;
  font-family: "Chilanka", Arial, Helvetica, sans-serif;
  letter-spacing: 0.6px;
  background-color: rgba(0,0,0,0.45);
  display: inline-block;
  padding: 5px 10px 3px 10px;
  margin: 0;
  font-size: 18px;
}

.App {
  text-align: center;
}

.App__Header {
  background-color: transparent;
  -webkit-box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.75);
  margin-bottom:55px;
  z-index: 1;
}

.App__Header__tag2 {
  color: #c7c9d4;
  font-size: 10px;
  font-weight: normal;
  font-family: "Chilanka", Arial, Helvetica, sans-serif;
  background-color: rgba(0,0,0,0.45);
  display: block;
  padding: 5px 10px 3px 10px;
  margin: 0;
  margin-top: -4px;
}

footer {
  margin-top: 345px;
  height: 300px;
  background-color: rgba(0,0,0,0.85);
  color: #fff;
  padding-top: 42px;
  text-align: center;
}

footer p {
  max-width: 500px;
  display: block;
  margin: auto;
  color: rgb(184, 184, 189);
}


